import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, Button, Form, Table, Pagination, InputGroup, Toast, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import supabase from "../services/supabase";

const API_URL = 'https://dashport-admin-api.lookermetrics.com';

export const AdminInterface = () => {
  const [users, setUsers] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [showLegacyAccounts, setShowLegacyAccounts] = useState(false);
  const [newUser, setNewUser] = useState({
    email: '',
    company: '',
    new_accounts: [''],
    accounts: [''],
    drive_url: '',
    custom_links: [{ name: '', url: '' }]
  });
  const [loading, setLoading] = useState('');
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [showPasswordToast, setShowPasswordToast] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState('');
  const toastRef = useRef(null);
  const [sortField, setSortField] = useState('last_sign_in_at');
  const [sortDirection, setSortDirection] = useState('desc');

  const getAccessToken = () => {
    const tokenKey = Object.keys(localStorage).find(key => key.endsWith('-auth-token'));
    if (tokenKey) {
      const tokenData = JSON.parse(localStorage.getItem(tokenKey));
      return tokenData.access_token;
    }
    return null;
  };

  // const fetchUsers = async () => {
  //   const { data, error } = await supabase.from('sitruna_parameters').select('*');
  //   if (error) {
  //     console.error('Error fetching users:', error);
  //     setError('Failed to fetch users');
  //   } else {
  //     setUsers(data.map(user => ({
  //       ...user,
  //       accounts: user.parameter ? user.parameter.split(',') : [],
  //       custom_links: Array.isArray(user.custom_links) ? user.custom_links : JSON.parse(user.custom_links || '[]')
  //     })));
  //   }
  // };
  const fetchUsers = useCallback(async () => {
    try {
      const accessToken = getAccessToken();
      if (!accessToken) throw new Error('No access token found');
  
      const response = await fetch(`${API_URL}/users`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
  
      if (!response.ok) throw new Error('Failed to fetch users');
      
      const data = await response.json();
  
      setUsers(data.map(user => ({
        ...user,
        accounts: user.parameter ? user.parameter.split(',') : [],
        new_accounts: user.new_parameter ? user.new_parameter.split(',') : [],
        custom_links: Array.isArray(user.custom_links) ? user.custom_links : JSON.parse(user.custom_links || '[]')
      })));
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users');
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);
  
  // Filter users based on search term
  const filteredUsers = users.filter(user =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const sortedUsers = [...filteredUsers].sort((a, b) => {
    if (!sortField) return 0;
  
    let aValue = a[sortField];
    let bValue = b[sortField];
  
    // Handle dates for last_sign_in_at
    if (sortField === 'last_sign_in_at') {
      aValue = aValue ? new Date(aValue).getTime() : 0;
      bValue = bValue ? new Date(bValue).getTime() : 0;
    }
  
    if (sortDirection === 'asc') {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  // Calculate pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  // const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const currentUsers = sortedUsers.slice(indexOfFirstUser, indexOfLastUser);  // Changed from filteredUsers to sortedUsers

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleInputChange = (e, index, field) => {
    if (field === 'accounts') {
      const updatedAccounts = [...newUser.accounts];
      updatedAccounts[index] = e.target.value;
      setNewUser({ ...newUser, accounts: updatedAccounts });
    } else if (field === 'new_accounts') {
      const updatedNewAccounts = [...newUser.new_accounts];
      updatedNewAccounts[index] = e.target.value;
      setNewUser({ ...newUser, new_accounts: updatedNewAccounts });
    } else if (field === 'custom_links') {
      const updatedLinks = [...newUser.custom_links];
      updatedLinks[index] = { ...updatedLinks[index], [e.target.name]: e.target.value };
      setNewUser({ ...newUser, custom_links: updatedLinks });
    } else {
      setNewUser({ ...newUser, [e.target.name]: e.target.value });
    }
  };

  const addField = (field) => {
    if (field === 'accounts') {
      setNewUser({ ...newUser, accounts: [...newUser.accounts, ''] });
    } else if (field === 'new_accounts') {
      setNewUser({ ...newUser, new_accounts: [...newUser.new_accounts, ''] });
    } else if (field === 'custom_links') {
      setNewUser({ ...newUser, custom_links: [...newUser.custom_links, { name: '', url: '' }] });
    }
  };

  const removeField = (index, field) => {
    if (field === 'accounts') {
      const updatedAccounts = newUser.accounts.filter((_, i) => i !== index);
      setNewUser({ ...newUser, accounts: updatedAccounts });
    } else if (field === 'new_accounts') {
      const updatedNewAccounts = newUser.new_accounts.filter((_, i) => i !== index);
      setNewUser({ ...newUser, new_accounts: updatedNewAccounts });
    } else if (field === 'custom_links') {
      const updatedLinks = newUser.custom_links.filter((_, i) => i !== index);
      setNewUser({ ...newUser, custom_links: updatedLinks });
    }
  };

  const generatePassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const password = Array.from({ length: 15 }, () => chars[Math.floor(Math.random() * chars.length)]).join('');
    setGeneratedPassword(password);
    return password;
  };

  const validateForm = () => {
    if (!newUser.email || !newUser.company) {
      setError('Please fill in all required fields.');
      return false;
    }
    
    // Check if at least one of the account types has valid entries
    const hasValidNewAccounts = newUser.new_accounts.length > 0 && !newUser.new_accounts.some(account => !account.trim());
    const hasValidOldAccounts = newUser.accounts.length > 0 && !newUser.accounts.some(account => !account.trim());
    
    if (!hasValidNewAccounts && !hasValidOldAccounts) {
      setError('Please provide at least one valid account.');
      return false;
    }
    
    if (newUser.custom_links.some(link => !link.name.trim() || !link.url.trim())) {
      setError('Please fill in both name and URL for all custom links or remove empty ones.');
      return false;
    }
    return true;
  };

  const createOrUpdateUser = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading('create');
    setError(null);

    const password = generatePassword();
    const userDataToSave = {
      email: newUser.email,
      password: password, // Only for new users
      userData: {
        company: newUser.company,
        parameter: newUser.accounts.join(','), // Convert accounts array to comma-separated string
        new_parameter: newUser.new_accounts.join(','), // Convert new_accounts array to comma-separated string
        drive_url: newUser.drive_url,
        custom_links: newUser.custom_links
      }
    };

    let response;
    try {
      const accessToken = getAccessToken();
      if (!accessToken) throw new Error('No access token found');

      const url = editingUser ? `${API_URL}/users/${editingUser.email}` : `${API_URL}/users`;
      const method = editingUser ? 'PUT' : 'POST';
      
      response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(userDataToSave)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create/update user');
      }

      setNewUser({ email: '', password: '', company: '', accounts: [''], new_accounts: [''], drive_url: '', custom_links: [{ name: '', url: '' }] });
      setEditingUser(null);
      setShowForm(false);
      fetchUsers();

      if (!editingUser){
        // Show the password toast
        setShowPasswordToast(true);
      }
    } catch (error) {
      console.error('Error creating/updating user:', error);
      setError(error.message || 'Failed to create/update user');
    } finally {
      setLoading('');
    }
  };

  const handleInviteUser = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
  
    setLoading('invite');
    setError(null);
  
    let response;
    try {
      const accessToken = getAccessToken();
      if (!accessToken) throw new Error('No access token found');
  
      const userDataToSave = {
        email: newUser.email,
        useInvitation: true, // Signal to use invitation flow
        userData: {
          company: newUser.company,
          parameter: newUser.accounts.join(','), // Convert accounts array to comma-separated string
          new_parameter: newUser.new_accounts.join(','), // Convert new_accounts array to comma-separated string
          drive_url: newUser.drive_url,
          custom_links: newUser.custom_links
        }
      };
  
      response = await fetch(`${API_URL}/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(userDataToSave)
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to invite user');
      }
  
      setNewUser({ email: '', company: '', accounts: [''], new_accounts: [''], drive_url: '', custom_links: [{ name: '', url: '' }] });
      setShowForm(false);
      fetchUsers();
      
      // Show success message
      setShowPasswordToast(false); // Hide password toast if visible
      alert('Invitation email sent successfully!');
      
    } catch (error) {
      console.error('Error inviting user:', error);
      setError(error.message || 'Failed to invite user');
    } finally {
      setLoading('');
    }
  };

  const deleteUser = async (email) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      let response;
      try {
        const accessToken = getAccessToken();
        if (!accessToken) throw new Error('No access token found');

        response = await fetch(`${API_URL}/users/${email}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to delete user');
        }
        
        fetchUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
        setError(error.message || 'Failed to delete user');
      }
    }
  };

  const copyPasswordToClipboard = () => {
    navigator.clipboard.writeText(generatedPassword).then(() => {
      // alert('Password copied to clipboard!');
    }, (err) => {
      console.error('Could not copy password: ', err);
    });
  };

  const startEditing = (user) => {
    setEditingUser(user);
    // Show legacy accounts section if the user has old accounts
    setShowLegacyAccounts(user.parameter && user.parameter.length > 0);
    setNewUser({
      ...user,
      accounts: user.parameter ? user.parameter.split(',') : [],
      new_accounts: user.new_parameter ? user.new_parameter.split(',') : [],
      custom_links: Array.isArray(user.custom_links) ? user.custom_links : JSON.parse(user.custom_links || '[]')
    });
    setShowForm(true);
  };

  const truncateUrl = (url, maxLength = 27) => {
    if (url.length <= maxLength) return url;
    return url.substring(0, maxLength - 3) + '...';
  };

  // Add this function to your AdminInterface component
  const handleExportCSV = () => {
    // Create CSV content
    const csvRows = [
      // Header row
      ['Email', 'Company', 'Accounts (New)', 'Accounts (Old)', 'Created At', 'Last Login'],
      // Data rows
      ...users.map(user => [
        user.email,
        user.company,
        user.new_accounts ? user.new_accounts.join(', ') : '',
        user.accounts.join(', '),
        user.created_at ? new Date(user.created_at).toLocaleString('en-GB') : '-',
        user.last_sign_in_at ? new Date(user.last_sign_in_at).toLocaleString('en-GB') : '-'
      ])
    ];

    // Convert to CSV string
    const csvContent = csvRows
      .map(row => row.map(cell => `"${cell}"`).join(','))
      .join('\n');

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `user-analytics-${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Card>
      <Card.Body>
        {!showForm ? (
          <>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <InputGroup className="w-50">
                <InputGroup.Text>
                    <i className="bi bi-search" style={{ fontSize: '18px', fontWeight: 'bold' }}></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search by email"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
              <div className="d-flex gap-2">
                <Button 
                  variant="outline-primary" 
                  onClick={handleExportCSV}
                  title="Export user analytics to CSV"
                >
                  <i className="bi bi-download" style={{ fontSize: '18px', fontWeight: 'bold', marginRight: '6px' }}></i>
                  Export User Analytics
                </Button>
                <Button onClick={() => setShowForm(true)}>
                  <i className="bi bi-plus-circle" style={{ fontSize: '18px', fontWeight: 'bold', marginRight: '6px' }}></i>
                  Add New User
                </Button>
              </div>
            </div>
            <div className="table-responsive">
              <Table className="mt-4">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Company</th>
                    <th>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>New format used by the current dashboard system - preferred for all new users</Tooltip>}
                      >
                        <span>Accounts (New)</span>
                      </OverlayTrigger>
                    </th>
                    <th>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Legacy format used by the old Looker Studio report</Tooltip>}
                      >
                        <span>Accounts (Old)</span>
                      </OverlayTrigger>
                    </th>
                    <th>Google Drive URL</th>
                    <th>Custom Links</th>
                    <th 
                      onClick={() => handleSort('last_sign_in_at')}
                      style={{ cursor: 'pointer' }}
                    >
                      Last Login {sortField === 'last_sign_in_at' && (sortDirection === 'asc' ? '↑' : '↓')}
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.map((user) => (
                    <tr key={user.email} style={{ height: '48px' }}>
                      <td>{user.email}</td>
                      <td>{user.company}</td>
                      <td style={{ textAlign: "center" }}>{user.new_accounts && user.new_accounts.length > 0 ? user.new_accounts.length : "-"}</td>
                      <td style={{ textAlign: "center" }}>{user.accounts.length > 0 ? user.accounts.length : "-"}</td>
                      <td>
                        {user.drive_url ? (
                          <a href={user.drive_url} target="_blank" rel="noopener noreferrer">
                            {truncateUrl(user.drive_url)}
                          </a>
                        ) : "-"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {user.custom_links.length > 0 ? user.custom_links.length : "-"}
                      </td>
                      <td>
                        {user.last_sign_in_at 
                          ? new Date(user.last_sign_in_at).toLocaleString('en-GB', {
                              day: 'numeric',
                              month: 'numeric',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit'
                            })
                          : "-"}
                      </td>
                      <td>
                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                          <Button variant="outline-primary" size="sm" onClick={() => startEditing(user)}>
                            <i className="bi bi-pencil" style={{ fontSize: '14px', fontWeight: 'bold' }}></i>
                          </Button>
                          <Button variant="outline-danger" size="sm" className="ms-2" onClick={() => deleteUser(user.email)}>
                            <i className="bi bi-trash" style={{ fontSize: '14px', fontWeight: 'bold' }}></i>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {Array.from({ length: Math.max(0, usersPerPage - currentUsers.length) }).map((_, index) => (
                    <tr key={`empty-${index}`} style={{ height: '48px' }}>
                      <td colSpan="8">&nbsp;</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <div style={{ fontSize: 'small', marginLeft: "4px" }}>
                {`${users.length} / 75 users (contact to increase limit)`}
              </div>
              <Pagination className="mt-3 justify-content-end">
                <Pagination.Prev disabled={currentPage === 1} onClick={() => currentPage > 1 && paginate(currentPage - 1)} />
                {[...Array(Math.ceil(users.length / usersPerPage)).keys()].map(number => (
                  <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
                    {number + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next disabled={currentPage === Math.ceil(users.length / usersPerPage)} onClick={() => currentPage < Math.ceil(users.length / usersPerPage) && paginate(currentPage + 1)} />
              </Pagination>
            </div>
          </>
        ) : (
          <Form onSubmit={createOrUpdateUser}>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={newUser.email}
                onChange={(e) => handleInputChange(e)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Company</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={newUser.company}
                onChange={(e) => handleInputChange(e)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{marginRight: "8px"}}>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>New format used by the current dashboard system. Recommended for all new users.</Tooltip>}
                >
                  <span>Accounts</span>
                </OverlayTrigger>
              </Form.Label>
              {newUser.new_accounts.map((account, index) => (
                <div key={index} className="d-flex mb-2">
                  <Form.Control
                    type="text"
                    value={account}
                    onChange={(e) => handleInputChange(e, index, 'new_accounts')}
                    required={newUser.accounts.length === 0 || newUser.accounts[0] === ''}
                  />
                  <Button variant="outline-danger" onClick={() => removeField(index, 'new_accounts')} className="ms-2">
                    <i className="bi bi-x" style={{ fontSize: '16px', fontWeight: 'bold' }}></i>
                  </Button>
                </div>
              ))}
              <Button variant="outline-primary" onClick={() => addField('new_accounts')}>
                <i className="bi bi-plus-circle" style={{ fontSize: '16px', fontWeight: 'bold' }}></i> Add Account
              </Button>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Drive URL</Form.Label>
              <Form.Control
                type="url"
                name="drive_url"
                value={newUser.drive_url}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{marginRight: "8px"}}>Custom Links</Form.Label>
              {newUser.custom_links.map((link, index) => (
                <div key={index} className="d-flex mb-2">
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Link Name"
                    value={link.name}
                    onChange={(e) => handleInputChange(e, index, 'custom_links')}
                    required
                  />
                  <Form.Control
                    type="url"
                    name="url"
                    placeholder="URL"
                    value={link.url}
                    onChange={(e) => handleInputChange(e, index, 'custom_links')}
                    required
                    className="ms-2"
                  />
                  <Button variant="outline-danger" onClick={() => removeField(index, 'custom_links')} className="ms-2">
                    <i className="bi bi-x" style={{ fontSize: '16px', fontWeight: 'bold' }}></i>
                  </Button>
                </div>
              ))}
              <Button variant="outline-primary" onClick={() => addField('custom_links')}>
                <i className="bi bi-plus-circle" style={{ fontSize: '16px', fontWeight: 'bold' }}></i> Add Custom Link
              </Button>
            </Form.Group>
            
            {/* Show legacy accounts section when editing a user with old accounts or when explicitly shown */}
            {((editingUser && (
              (editingUser.parameter && editingUser.parameter.length > 0) || 
              (newUser.accounts && newUser.accounts.length > 0 && newUser.accounts[0] !== '')
            )) || showLegacyAccounts) ? (
              <Form.Group className="mb-3">
                <Form.Label style={{marginRight: "8px"}}>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Legacy format used by the old Looker Studio report. Only needed for users on the old report.</Tooltip>}
                  >
                    <span>Legacy Accounts (Old)</span>
                  </OverlayTrigger>
                </Form.Label>
                {newUser.accounts.map((account, index) => (
                  <div key={index} className="d-flex mb-2">
                    <Form.Control
                      type="text"
                      value={account}
                      onChange={(e) => handleInputChange(e, index, 'accounts')}
                      required={newUser.new_accounts.length === 0 || newUser.new_accounts[0] === ''}
                    />
                    <Button variant="outline-danger" onClick={() => removeField(index, 'accounts')} className="ms-2">
                      <i className="bi bi-x" style={{ fontSize: '16px', fontWeight: 'bold' }}></i>
                    </Button>
                  </div>
                ))}
                <Button variant="outline-primary" onClick={() => addField('accounts')}>
                  <i className="bi bi-plus-circle" style={{ fontSize: '16px', fontWeight: 'bold' }}></i> Add Legacy Account
                </Button>
                <small className="text-muted d-block mt-1">
                  Legacy format - only needed for users on the old Looker Studio report.
                </small>
              </Form.Group>
            ) : (
              <div className="mb-3">
                <Button 
                  variant="outline-secondary" 
                  size="sm" 
                  onClick={() => setShowLegacyAccounts(true)}
                >
                  <i className="bi bi-plus-circle" style={{ fontSize: '14px', fontWeight: 'bold', marginRight: '4px' }}></i>
                  Show Legacy Account Options
                </Button>
                <small className="text-muted ms-2">
                  Only needed for users on the old Looker Studio report
                </small>
              </div>
            )}
            <div className="d-flex gap-2">
              <Button 
                type="submit" 
                disabled={loading}
              >
                {loading === 'create' ? 'Saving...' : (editingUser ? 'Update User' : 'Create with Password')}
              </Button>
              {!editingUser && (
                <Button 
                  variant="warning" 
                  onClick={handleInviteUser}
                  disabled={loading}
                >
                  {loading === 'invite' ? 'Sending...' : 'Send Invitation Email'}
                </Button>
              )}
              <Button 
                variant="secondary" 
                onClick={() => {
                  setShowForm(false);
                  setEditingUser(null);
                  setShowLegacyAccounts(false);
                  setNewUser({ email: '', company: '', accounts: [''], new_accounts: [''], drive_url: '', custom_links: [{ name: '', url: '' }] });
                }} 
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
        {error && <p className="text-danger mt-3">{error}</p>}
        <Toast
          ref={toastRef}
          onClose={() => setShowPasswordToast(false)}
          show={showPasswordToast}
          delay={30000}
          autohide
          style={{
            position: 'fixed',
            top: 20,
            right: 20,
            minWidth: 300,
            zIndex: 1000
          }}
        >
          <Toast.Header>
            <strong className="me-auto">New User's Password</strong>
          </Toast.Header>
          <Toast.Body>
            <p>Password: {generatedPassword}</p>
            <Button onClick={copyPasswordToClipboard} variant="primary" size="sm">
              Copy Password
            </Button>
          </Toast.Body>
        </Toast>
      </Card.Body>
    </Card>
  );
};
